body {
	font-family: 'Montserrat',Helvetica,Arial,Lucida,sans-serif;
	overflow-x: hidden;
	background-color: #ffffff;
	margin: 0;
	height: 100%;
}
h1,h2,h3,h4,p {margin: 0;}


.login_div {
	position: fixed;
	z-index: 100;
	width: 100vw; height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: white;
}
.login_username {
	border: none;
	border-bottom: 0.125rem solid black;
	outline: none;
	width: 200px; height: 35px;
	background-color: #fbfbfb;
	transition: all 0.3s ease;
	margin-bottom: 15px; margin-top: 10px;
	padding-left: 15px;
}
.login_username:focus {
	background-color: #e9f6ff;
}
.login_password {
	border: none;
	border-bottom: 0.125rem solid black;
	outline: none;
	width: 200px; height: 35px;
	background-color: #fbfbfb;
	transition: all 0.3s ease;
	margin-bottom: 10px; padding-left: 15px;
}
.login_password:focus {
	background-color: #e9f6ff;
}

.login_button {
	padding: 10px;
	width: 100px;
	border: none;
	border-radius: 3px;
	/*box-shadow: 0px 0px 12px -2px rgba(0, 0, 0, 0.5);*/
	background-color: #fc6e51;
	color: white;
	font-size: 16px;
	transition: background-color 0.3s ease;
	overflow: hidden;
	cursor: pointer;
}
.login_button:hover {background-color: #ff7b62;}

.transparent {
  opacity: 0 !important;
  transition: opacity 1s ease-in-out;
}
.white_block_for_reload {
    width: 100%; height: 100%;
    position: absolute;
    top: 0; left: 0;
    background-color: white;
    opacity: 1;
    transition: opacity 1s ease-in-out;
}

.white_block_for_reload_t {
  opacity: 0 !important; transition: opacity 1s ease-in-out;
}
td > input { text-align: center; }


.full_table_spisok {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 1000px;
    margin-bottom: 30px;
}
.table_spisok_one_div {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    width: 200px;
    padding: 10px 10px 15px;
    border-radius: 10px;
    margin: 0 20px 10px 0;
    background-color: #fbfdff;
    position: relative;
    transition: all 0.3s ease;
    box-shadow: rgb(115 130 145 / 40%) 0px 3px 5px;
}

.table_spisok_one_div > div {font-weight: 600; color: #333}
.table_spisok_one_input_div {
    position: absolute;
    left: 10px; bottom: 0px;
    display: flex;
    flex-direction: row;
    font-size: 11px;
    color: #727272;
    font-weight: 600;
}
.table_spisok_one_input_div > p {
    align-self: end;
    margin-right: 3px;
    height: 14px;
}
.table_spisok_one_input_date {
    width: 82px;
    font-size: 11px;
    color: #727272;
    font-weight: 600;
}
.table_spisok_one_div_type {
    position: absolute;
    right: 0px; top: 0;
    border-radius: 0px 7px 7px 0;
    width: 44px; height: 100%;
    display: flex; flex-direction: column; justify-content: center; align-items: center;
    font-size: 22px;
}
.table_spisok_one_div_type-approved {
    position: absolute;
    right: 0px; top: 0;
    border-radius: 0px 7px 7px 0;
    width: 60px; height: 100%;
    display: flex; flex-direction: column; justify-content: center; align-items: center;
    font-size: 22px;
}
.table_spisok_one_div_type > i {
    width: 20px; height: 20px;
}
.table_spisok_one_div_type-approved > p {
    font-size: 11px;
    /*align-self: end;*/
    /*margin-top: auto;*/
    height: 12px;
    bottom: 0;
    color: #585858;
    font-weight: 600;
    position: absolute;
}


.checkbox-wrapper { margin: auto 15px auto 0px; display: flex;}
/*.checkbox-wrapper + .checkbox-wrapper {margin-right: 15px;}*/
.checkbox-wrapper input[type="checkbox"] {display: none; visibility: hidden;}
.checkbox-wrapper label {
	display: inline-block;
	color: #313131;
	font-weight: 600;
	font-size: 14px;
	width: 30px;
    margin-top: auto;
    margin-bottom: auto;
}
.checkbox-wrapper .cbx {
    position: relative;
    top: 1px;
    width: 17px;
    height: 17px;
    border: 1px solid #c8ccd4;
    border-radius: 3px;
    vertical-align: middle;
    transition: background 0.1s ease;
    cursor: pointer;
}
.checkbox-wrapper .cbx:after {
    content: '';
    position: absolute;
    top: 1px;
    left: 5px;
    width: 5px;
    height: 11px;
    opacity: 0;
    transform: rotate(45deg) scale(0);
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    transition: all 0.3s ease;
    transition-delay: 0.15s;
}
.checkbox-wrapper .lbl {
	margin-left: 5px;
	margin-top: 3px;
    font-size: 24px;
/*	display: grid;*/
/*	height: 100%;*/
/*	align-self: flex-end;*/
/*    margin-left: 5px;*/
    vertical-align: middle;
    cursor: pointer;

}
.checkbox-wrapper input[type="checkbox"]:checked ~ .cbx {
    border-color: transparent;
    background: #6871f1;
    animation: jelly-42 0.6s ease;
}
.checkbox-wrapper input[type="checkbox"]:checked ~ .cbx:after {
    opacity: 1;
    transform: rotate(45deg) scale(1);
}
.checkbox-wrapper .cntr {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    text-align: center;
}
@-moz-keyframes jelly-42 {
from { transform: scale(1, 1); }
30% {transform: scale(1.25, 0.75);}
40% {transform: scale(0.75, 1.25);}
50% {transform: scale(1.15, 0.85);}
65% {transform: scale(0.95, 1.05);}
75% {transform: scale(1.05, 0.95);}
to {transform: scale(1, 1);}
}
@-webkit-keyframes jelly-42 {
from { transform: scale(1, 1); }
30% {transform: scale(1.25, 0.75);}
40% {transform: scale(0.75, 1.25);}
50% {transform: scale(1.15, 0.85);}
65% {transform: scale(0.95, 1.05);}
75% {transform: scale(1.05, 0.95);}
to {transform: scale(1, 1);}
}
@-o-keyframes jelly-42 {
from { transform: scale(1, 1); }
30% {transform: scale(1.25, 0.75);}
40% {transform: scale(0.75, 1.25);}
50% {transform: scale(1.15, 0.85);}
65% {transform: scale(0.95, 1.05);}
75% {transform: scale(1.05, 0.95);}
to {transform: scale(1, 1);}
}
@keyframes jelly-42 {
from { transform: scale(1, 1); }
30% {transform: scale(1.25, 0.75);}
40% {transform: scale(0.75, 1.25);}
50% {transform: scale(1.15, 0.85);}
65% {transform: scale(0.95, 1.05);}
75% {transform: scale(1.05, 0.95);}
to {transform: scale(1, 1);}
}






.load_table_admin {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin-top: 40px;
}
.load_table {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    margin-top: 40px;
}





.login_button_div {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 215px;
    justify-content: flex-start;
}

.loader {
	/*position: fixed;*/
	/*right: 0;*/
	width: 20px; height: 20px;
	margin-left: 15px;
    aspect-ratio: 1;
    border-radius: 50%;
    border: 4px solid;
    border-color: #000 #0000;
    animation: l1 1s infinite;
	transition: border-color 0.3s ease;
}
@keyframes l1 {to{transform: rotate(.5turn)}}

.loader_success {border-color: #13c400 #fff;}
.loader_error {border-color: #e00000 #fff;}


.loader_sendget_file {
	z-index: 20;
	position: fixed;
	top: 10px;
    right: 10px;
    width: 10px;
    aspect-ratio: 1;
    border: 5px solid #ddd;
    border-radius: 50%;
    transform: rotate(45deg);
}
.loader_sendget_file::before {
    content: "";
    position: absolute;
    inset: -5px;
    border-radius: 50%;
    border: 5px solid #514b82;
    animation: l18 10s infinite linear;
}
@keyframes l18 {
    0%   {clip-path:polygon(50% 50%,0 0,0    0,0    0   ,0    0   ,0    0   )}
    25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0   ,100% 0   ,100% 0   )}
    50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
    75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0    100%,0    100%)}
    100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0    100%,0    0   )}
}

.user_type_icon {
    z-index: 20;
    position: fixed;
    top: 6px;
    right: 45px;
    width: 20px;
    aspect-ratio: 1;
    border: none;
    cursor: pointer;
    font-size: 26px;
}

/*.loader_success {*/
/*	margin-left: 15px;*/
/*	width: 40px;*/
/*	aspect-ratio: 4;*/
/*	--_g: no-repeat radial-gradient(circle closest-side, #525252 90%,#0000);*/
/*	background:*/
/*	var(--_g) 0%   50%,*/
/*	var(--_g) 50%  50%,*/
/*	var(--_g) 100% 50%;*/
/*	background-size: calc(100%/3) 100%;*/
/*	animation: l7 1s infinite linear;*/
/*}*/
/*@keyframes l7 {*/
/*    33%{background-size:calc(100%/3) 0%  ,calc(100%/3) 100%,calc(100%/3) 100%}*/
/*    50%{background-size:calc(100%/3) 100%,calc(100%/3) 0%  ,calc(100%/3) 100%}*/
/*    66%{background-size:calc(100%/3) 100%,calc(100%/3) 100%,calc(100%/3) 0%  }*/
/*}*/



.full_tables_div {
	width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 300px;
}
.login_form {
	position: fixed;
	z-index: 100;
    left: 0;
    top: 0;
}





input {
	margin: 0;
	width: max-content;
	border: none;
	background-color: transparent;
}
table {
	border-spacing: 0;table-layout: auto;
	margin: 50px 27px 0 0px;
}
td {
	margin: 0; padding: 2px 0 1px;
	border-bottom: solid 1px #cccccc;
	font-size: 13px;
    font-weight: bold;
}



.td_remarks {
    text-align: left;
    border-right: 2px solid black;
    overflow-x: hidden;
    padding: 0;
    width: 150px;
    /*display: flex;*/
    /*flex-direction: row;*/
    /*align-items: end;*/
}
.td_remarks > div {
    width: 150px;
    overflow-x: auto;
    white-space: nowrap;
    /*margin: auto 0 0;*/
}
.td_remarks > div::-webkit-scrollbar { height: 6px; }
.td_remarks > div::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: #e7e7e7;
    border: 1px solid #cacaca;
}
.td_remarks > div::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #576bbf;
}




.button_newrow {
	margin-left: 5px;
    padding: 0;
    border: none;
    background-color: white;
    cursor: pointer;
    display: flex;
    height: 13px;
}
.button_createtable {
	margin-left: 5px;
    padding: 0;
    border: none;
    background-color: white;
    cursor: pointer;
    display: flex;
    height: 13px;
}
.button_rowtoselected_div {
    margin-top: 27px;
    height: auto;
    display: flex; justify-content: center;
    align-items: end;
    width: 27px;
}
.button_rowtoselected {
	/*margin-right: 5px;*/
    width: 27px;
    display: flex; justify-content: center;
    padding: 0; margin-bottom: -27px;
    border: none;
    background-color: white;
    cursor: pointer;
    display: flex;
    font-size: 27px;
    /*height: 13px;*/
}
.button_shuffle {
	margin-left: 15px;
    padding: 0;
    border: none;
    background-color: white;
    cursor: pointer;
    display: flex;
    height: 20px;
	font-size: 25px;
}



.button_submitapprove {
	margin-left: 5px;
    width: 27px;
    display: flex; justify-content: center;
    padding: 0;

    border: none;
    background-color: white;
    cursor: pointer;
    display: flex;
    font-size: 20px;
    height: 13px;
    /* margin-bottom: -22px; */
    margin-top: 2px;
}


thead > tr > th {font-size: 14px; height: 19px}

.toApproveOption {
  background-color: #ffec8f;
}

.approvedOption {
  background-color: #caffbb;
}

.defaultOption {
  background-color: #fff;
}



.selected_row {
	/*background-color: #8fb4e9;*/
	background-color: #c3daf9;
	transition: all 0.2s ease;;
}
.row_in_selected {
	/*background-color: #c1d7ef;*/
	background-color: #f3f3f3;
	transition: all 0.2s ease;
}
.full_selected_table tr:nth-child(even) {
  background-color: #fcfcfc;
}

.table_calculate {border: 2px solid black; border-radius: 0px 0px 10px 10px }
.table_calculate > tbody > tr > td {
    text-align: center;
    font-weight: 100;
}
.selectedtable_tr {
    font-size: 11px;
    font-weight: 100;
    height: 15px;
}
.selectedtable_tr > td {
    font-size: 11px;
    font-weight: 100;
    text-align: center;
}
tr {
    height: 23px}


input[type="radio"] {
  --s: 17px; /* control the size */
  --c: #5b82b9; /* the active color */
  margin-left: 5px;
  margin-right: 5px;
  height: var(--s);
  aspect-ratio: 1;
  border: calc(var(--s) / 8) solid #939393;
  padding: calc(var(--s) / 8);
  background: radial-gradient(farthest-side, var(--c) 94%, #0000) 50%/0 0
    no-repeat content-box;
  border-radius: 30%;
  outline-offset: calc(var(--s) / 10);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  font-size: inherit;
  transition: 0.3s;
}
input[type="radio"]:checked {
  border-color: var(--c);
  background-size: 100% 100%;
}

input[type="radio"]:disabled {
  background: linear-gradient(to bottom, #939393, white);

  opacity: 0.5;
  cursor: not-allowed;
}








